import Link from 'next/link';

export default function SimpleMenu() {
  const navItems = [
    { text: 'Home', link: '/' },
  
  
    // Add other items as needed
  ];

  return (
    <nav className="simple-menu">
      <ul>
        {navItems.map((item, index) => (
          <li key={index}>
            {/* If the item has a link, render it as a Link; otherwise, just display the text */}
            {item.link ? (
              <Link href={item.link}>{item.text}</Link>
            ) : (
              <span>{item.text}</span>
            )}

            {/* If the item has subItems, render them in a nested list */}
            {item.subItems && (
              <ul>
                {item.subItems.map((subItem, subIndex) => (
                  <li key={subIndex}>
                    <Link href={subItem.link}>{subItem.text}</Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
}